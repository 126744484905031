import { Column } from 'primereact/column'
import { InputSwitch } from 'primereact/inputswitch'
import React from 'react'
import DataList from '../../../components/layout/DataList'
import Page from '../../../components/layout/Page'
import ResponsiveColumn from '../../../components/utils/ResponsiveColumn'
import useList from '../../../hook/useList'
import ConfigModulosService from '../../../services/configuracao/ConfigModulosService'

const ConfiguracoesModuloWeb = (props) => {
  const { state, page, onFilter, onPageChange, onSelect, getAll } = useList({}, ConfigModulosService, '')

  const updateModulos = async (codban, codmod) => {
    try {
      await ConfigModulosService.updateModulos(codban, codmod)
      getAll()
    } catch (error) {
      console.error(error)
    }
  }

  const getModuloVenda = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloVenda"
        checked={String(data.modweb).includes(1)}
        onChange={(e) => updateModulos(data.codbd_mwb || data.codigo_ban, 1)}
      />
    </div>
  )

  const getModuloPeca = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloPeca"
        checked={String(data.modweb).includes(2)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 2)}
      />
    </div>
  )

  const getModuloAutoGesto = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloAutoGesto"
        checked={String(data.modweb).includes(3)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 3)}
      />
    </div>
  )

  const getModuloAutoConf = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloAutoConf"
        checked={String(data.modweb).includes(4)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 4)}
      />
    </div>
  )

  const getModuloDealerUp = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloDeler"
        checked={String(data.modweb).includes(5)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 5)}
      />
    </div>
  )

  const getModuloYamaha = (data) => (
    <div className="flex justify-content-around">
      <InputSwitch
        className="table-preferences-switch"
        name="moduloYamaha"
        checked={String(data.modweb).includes(6)}
        onChange={() => updateModulos(data.codbd_mwb || data.codigo_ban, 6)}
      />
    </div>
  )

  return (
    <Page>
      <div className="flex justify-content-between align-items-center page-header">
        <h4>Configuração de módulos</h4>
      </div>
      <div className='w-full pt-4'>
        <DataList
          data={state.list}
          selected={state.selected}
          onSelect={(e) => onSelect(e)}
          notSearch={true}
          rows={state.rows}
          totalRecords={state.totalRecords}
          onPage={onPageChange}
          first={state.first}
          expandedRows={state.expandedRows}
          page={page}
          onFilter={onFilter}
          showFilter={true}
          // scrollable={true}
          frozenWidth="300px"
          customMaxHeight={'unset'}
        >
          <Column
            header="Empresa"
            alignFrozen="right"
            frozen
            body={(data) => (
              <ResponsiveColumn
                className="pl-2 w-full"
                column="Empresa"
                value={data.name_ban}
              />
            )}
            sortable={true}
            field="name_ban"
          />
          <Column
            header="Venda de veículo"
            body={(data) => (
              <ResponsiveColumn
                column="Venda de veículo"
                value={getModuloVenda(data)}
              />
            )}
            sortable={true}
            field="modulo1"
          />
          <Column
            header="Venda de peça"

            body={(data) => (
              <ResponsiveColumn
                column="Venda de peça"
                value={getModuloPeca(data)}
              />
            )}
            sortable={true}
            field="modulo1"
          />
          <Column
            header="AutoGestor"

            body={(data) => (
              <ResponsiveColumn
                column="AutoGestor"
                value={getModuloAutoGesto(data)}
              />
            )}
            sortable={true}
            field="modulo2"
          />
          <Column
            header="AutoConf"

            body={(data) => (
              <ResponsiveColumn
                column="AutoConf"
                value={getModuloAutoConf(data)}
              />
            )}
            sortable={true}
            field="modulo3"
          />
          <Column
            header="DealerUp"

            body={(data) => (
              <ResponsiveColumn
                column="DealerUp"
                value={getModuloDealerUp(data)}
              />
            )}
            sortable={true}
            field="modulo4"
          />
          <Column
            header="Yamaha A1"
            body={(data) => (
              <ResponsiveColumn
                column="Yamaha A1"
                value={getModuloYamaha(data)}
              />
            )}
            sortable={true}
            field="modulo5"
          />
        </DataList>
      </div>
    </Page>
  )
}

export default ConfiguracoesModuloWeb
