import { AutoComplete } from 'primereact/autocomplete'
import { classNames } from 'primereact/utils'
import React from 'react'
import AppButton from '../../layout/AppButton'
import FiltroModal from '../../utils/FiltroModal'
import RegisterModal from '../../utils/RegisterModal'
import InputBase from '../base/InputBase'

class SearchInput extends InputBase {
  constructor (props) {
    super(props)

    this.state = {
      visibleFiltro: false
    }
  }

  componentDidMount () {
    if (this.props.abriModalAtalho) {
      window.addEventListener('keydown', (e) => this.abrirFiltroModal(e))
      return () => {
        window.removeEventListener('keydown', (e) => this.abrirFiltroModal(e))
      }
    }
  }

  abrirFiltroModal (event) {
    if (event.altKey && event.key === 'a') {
      this.setState({ visibleFiltro: true })
    }
  }

  render () {
    const { props } = this

    const isCampoObrigatorio = Object.getOwnPropertyNames(props).includes('isFormFieldValid')

    const classNameLabel = isCampoObrigatorio ? classNames({ 'p-error': props.isFormFieldValid }, 'required') : ''
    return (
      <>
        {!props.ComponenteCadastro && (
          <label className={classNameLabel}>{props.label}</label>
        )}
        {props.ComponenteCadastro && (
          <RegisterModal
            ComponenteCadastro={props.ComponenteCadastro}
            isCampoObrigatorio={isCampoObrigatorio}
            onRegister={props.onRegister}
            label={props.label}
            isFormFieldValid={props.isFormFieldValid}
            onSaveModal={props.onSaveModal}
            onDeleteModal={props.onDeleteModal}
            modalHeader={props.modalHeader}
            selected={props.selected}
            primarykeyname={props.primarykeyname}
          />
        )}
        {!props.filtrotitle && (
          <AutoComplete
            field={props.field}
            value={props.value}
            minLength={props.minLength || 3}
            suggestions={props.suggestions}
            completeMethod={props.completeMethod}
            onChange={props.onChange}
            onSelect={async (e) => props.onSelect(e)}
            itemTemplate={props.itemTemplate}
            placeholder={props.placeholder || 'Digite para pesquisar.'}
            disabled={props.disabled}
            className={props.className}
            onBlur={props.onBlur}
            {...props}
          />
        )}
        {props.filtrotitle && (
          <>
            <div className="p-inputgroup">
              <AutoComplete
                {...props}
                field={props.field}
                value={props.value}
                minLength={props.minLength || 3}
                suggestions={props.suggestions}
                completeMethod={props.completeMethod}
                onChange={props.onChange}
                onSelect={async (e) => props.onSelect(e)}
                itemTemplate={props.itemTemplate}
                placeholder={props.placeholder || 'Digite para pesquisar.'}
                disabled={props.disabledAutoComplete !== undefined ? props.disabledAutoComplete : props.disabled}
                className={props.className}
                onBlur={props.onBlur}
              />
              <AppButton
                type="button"
                className="search-input__button-search"
                icon="pi pi-search"
                onClick={() => this.setState({ visibleFiltro: true })}
                disabled={props.disabled}
                onBlur={props.searchOnBlur}
              />
            </div>
            <FiltroModal
              title={props.filtrotitle}
              visible={this.state.visibleFiltro}
              hide={() => this.setState({ visibleFiltro: false })}
              service={props.service}
              model={props.model}
              primarykeyname={props.primarykeyname}
              columns={props.columns}
              onSelect={props.onSelect}
              filtersDefault={props.filtersDefault}
              semGetInicial={props.semGetInicial}
            />
          </>
        )}
      </>
    )
  }
}

export default SearchInput
