import * as queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { showErrorMessage } from '../components/utils/Message'

const useList = (Model, Service, resource, primaryKey = 'id', rows = 20, opcaoSelecionada = 't', ...props) => {
  const history = useHistory()
  const [state, setState] = useState({
    visible: false,
    visibleConfirm: false,
    confirmDescription: '',
    confirmTitle: '',
    onConfirmCallback: () => cancelConfirm(),
    filterCollapsed: true,
    selected: Model,
    filter: {},
    list: [],
    rows: rows,
    page: 1,
    first: 1,
    totalRecords: 0,
    totalPages: 0,
    opcaoSelecionada: opcaoSelecionada,
    meta: {},
    modelService: Service,
    semGetInicial: props[0]?.semGetInicial || false,
    ...props[0]
  })

  function addEventListeners () {
    if (!state.columns) return

    const campos = state.columns.map(column => column.key)

    for (const campo of campos) {
      const elemento = document.querySelector(`.${campo}`)

      if (!elemento || !campo) continue

      elemento.addEventListener('click', async () => {
        const { filter } = state
        filter.ordemCampo = filter.ordemCampo === `${campo}|asc` ? `${campo}|desc` : `${campo}|asc`
        await setState((prevState) => ({ ...prevState, filter }))
        onFilter(filter)
      })
    }
  }

  async function resetDescricaoFiltro () {
    const { filter } = state
    filter.descricaoFiltro = undefined
    filter.descricaoCodigo = undefined
    await setState((prevState) => ({ ...prevState, filter }))
  }

  async function getAll () {
    try {
      if (state.semGetInicial) {
        await setState((prevState) => ({ ...prevState, semGetInicial: false }))
        return
      }

      const filters = await toFilter(state.filter)

      let response = await state.modelService.getAll(filters, state.page, state.rows)
      await setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao listar os registros!')
    }
  }

  async function onFilter (filterPersonalizadoInstantaneo) {
    try {
      let stateCustomFilter = filterPersonalizadoInstantaneo || {}
      const customState = state

      if (state.semGetInicial) {
        await setState((prevState) => ({ ...prevState, semGetInicial: false }))
        return
      }

      if (stateCustomFilter?.page) {
        customState.page = stateCustomFilter?.page
        stateCustomFilter = {}
      }

      const filters = await toFilter({
        ...customState.filter,
        ...props[0].filtersDefault,
        ...stateCustomFilter
      })

      let response = await customState.modelService.filter(
        filters,
        customState.page,
        customState.rows
      )

      await setRetorno(response)
    } catch (error) {
      showErrorMessage(error.message || 'Houve um erro ao filtrar os registros!')
    }
  }

  async function setRetorno (response) {
    await setState((prevState) => ({
      ...prevState,
      list: response.data,
      totalRecords: response.meta.total,
      totalPages: response.meta.last_page,
      meta: response.meta,
      ...response
    }))
  }

  async function toFilter (obj) {
    const validFields = Object
      .keys(obj)
      .filter(it => !!obj[it])
      .map(it => ({ [it]: obj[it] }))

    let fields = {}

    validFields.forEach(it => {
      fields = { ...it, ...fields }
    })

    return queryString.stringify(fields)
  }

  async function onPageChange (data) {
    console.log(data)
    const first = data.first
    const page = data.page + 1
    await setState((prevState) => ({ ...prevState, first, page }))
    await onFilter({ page })
  }

  async function onView (selected) {
    history.push(`/${resource}/${selected[primaryKey]}`)
  }

  async function handleChangeFilter (event) {
    const filter = state.filter
    if (event.target.value.length === 0) {
      delete filter[event.target.name] // Remove se o valor for vazio
    } else {
      filter[event.target.name] = event.target.value
    }

    if (!!state?.deleteFilters?.length) {
      state?.deleteFilters?.map((palavra) =>
        delete filter[palavra]
      )
    }

    await setState((prevState) => ({ ...prevState, filter }))
  }

  async function onSelect (selected) {
    await setState((prevState) => ({ ...prevState, selected }))
  }

  async function onNew () {
    history.push(`/${resource}`)
  }

  async function setColumns (columns) {
    await setState((prevState) => ({ ...prevState, columns }))
  }

  async function handleRowExpansion (e) {
    let { expandedRows } = state
    if (expandedRows && expandedRows[0] === e) {
      expandedRows = null
    } else {
      expandedRows = [e]
    }

    await setState((prevState) => ({ ...prevState, expandedRows }))
  }

  async function showConfirm () {
    await setState((prevState) => ({ ...prevState, visibleConfirm: true }))
  }

  async function showCustomConfirm (title, description, onConfirmCallback) {
    await setState((prevState) => ({ ...prevState, confirmTitle: title, confirmDescription: description, onConfirmCallback }))
    showConfirm()
  }

  async function cancelConfirm () {
    await setState((prevState) => ({ ...prevState, visibleConfirm: false }))
  }

  useEffect(() => {
    const initialize = async () => {
      if (getAll) await getAll()
      if (addEventListeners) addEventListeners()
      if (resetDescricaoFiltro) resetDescricaoFiltro()
    }

    initialize()
  }, [])

  return {
    state,
    page: state.page,
    onPageChange,
    onFilter,
    handleChangeFilter,
    onSelect,
    onNew,
    handleRowExpansion,
    showCustomConfirm,
    cancelConfirm,
    onView,
    getAll,
    setColumns,
    setState,
    addEventListeners,
    resetDescricaoFiltro
  }
}

export default useList
