import CommonHelper from "../../../helpers/CommonHelper";
import AppButton from "../AppButton";
import DropdownMenu from "../DropdownButton/DropdownMenu";


export default function BotoesHeaderForm({
  isNew,
  removeBotoesHeader,
  actions,
  semExcluir,
  apenasBotaoAcoes,
  onSave,
  form,
  setVisibleCancelarModal,
  setVisibleExcluirModal,
  edicaoHabilitada,
  setEdicaoHabilitada,
}) {
  function BotoesExcluirOuCancelar () {
    return !edicaoHabilitada
      ? semExcluir 
        ? <></> 
        : <AppButton
          label={CommonHelper.isDesktop() ? 'Excluir' : ''}
          className="p-button-danger mr-2"
          icon="pi pi-trash"
          type="button"
          onClick={() => setVisibleExcluirModal(true)}
          disabled={edicaoHabilitada}
        />
      : <AppButton
        label={CommonHelper.isDesktop() ? 'Cancelar' : ''}
        className="p-button-danger mr-2"
        icon="pi pi-times"
        type="button"
        onClick={() => {
        setVisibleCancelarModal(true)
        }}
      />
  }
  
  function BotoesEditarOuSalvar () {
    return !edicaoHabilitada
      ? <AppButton
        type="button"
        label={CommonHelper.isDesktop() ? 'Editar' : ''}
        className={"mr-2"}
        icon={"pi pi-pencil"}
        onClick={() => setEdicaoHabilitada(true)}
      />
      : <AppButton
        type="submit"
        label={CommonHelper.isDesktop() ? 'Salvar' : ''}
        icon="pi pi-check"
        className="mr-2"
        onClick={async () => await onSave(form.values)}
      />
  }

  if (removeBotoesHeader) return null

  // Se não for registro novo, aparece os botões:
  // Excluir (Se "semExcluir" estiver false) / Cancelar
  // Editar / Salvar
  if (!isNew) {

    // Confere se tem ações para o form/page
    // Habilita Dropdown para as opções
    if (actions) {
      return (
        <div className="flex justify-content-end align-items-center">
          {!apenasBotaoAcoes && <BotoesExcluirOuCancelar />}
          {!apenasBotaoAcoes && <BotoesEditarOuSalvar />}
          <DropdownMenu
            items={actions}
            icon="pi pi-bars"
            label={CommonHelper.isDesktop() ? 'Ações' : ''}
            disabled={edicaoHabilitada}
          />
        </div>
      );
    }

    // Se não retorna botões padrões de form edit
    return (
      <div className="flex">
        <BotoesExcluirOuCancelar />
        <BotoesEditarOuSalvar />
      </div>
    );

  }

  // Se for novo, aparece apenas o botão Salvar
  return (
    <AppButton
      type="submit"
      label={CommonHelper.isDesktop() ? 'Salvar' : ''}
      icon="pi pi-check"
      className="mr-2"
      onClick={async () => await onSave(form.values)}
    />
  )
};
