import PrimeReact, { addLocale } from 'primereact/api'
import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import ErrorPage from './components/error/ErrorPage'
import PrivateRoute from './components/utils/PrivateRoute'
import PublicRoute from './components/utils/PublicRoute'
import { store } from './redux/store'
import { PessoaFisica } from './views/cadastro/pessoa/fisica/PessoaFisica'
import { PessoaFisicaList } from './views/cadastro/pessoa/fisica/PessoaFisicaList'
import { PessoaJuridica } from './views/cadastro/pessoa/juridica/PessoaJuridica'
import { PessoaJuridicaList } from './views/cadastro/pessoa/juridica/PessoaJuridicaList'
import { Usuario } from './views/cadastro/pessoa/usuario/Usuario'
import { UsuarioList } from './views/cadastro/pessoa/usuario/UsuarioList'
import { Acessorio } from './views/cadastro/veiculo/acessorio/Acessorio'
import { AcessorioList } from './views/cadastro/veiculo/acessorio/AcessorioList'
import { CorList } from './views/cadastro/veiculo/cor/CorList'
import { MarcaList } from './views/cadastro/veiculo/marca/MarcaList'
import { ModeloList } from './views/cadastro/veiculo/modelo/ModeloList'
import { VeiculoList } from './views/cadastro/veiculo/veiculo/VeiculoList'
import { Dashboard } from './views/dashboard/Dashboard'
import { Integracao } from './views/integracao/Integracao'
import { IntegracaoList } from './views/integracao/IntegracaoList'
import { Login } from './views/login/Login'
import Negociacao from './views/vendas/negociacao/Negociacao'
import { Avaliacao } from './views/vendas/negociacao/avaliacao/Avaliacao'
import { AvaliacaoList } from './views/vendas/negociacao/avaliacao/AvaliacaoList'
import { GerenciamentoEstoqueVeiculos } from './views/vendas/negociacao/gerenciamentoestoque/GerenciamentoEstoqueVeiculos'
import NegociosFechadosList from './views/vendas/negociacao/negociosFechados/NegociosFechadosList'
import { PagarConsignadosList } from './views/vendas/negociacao/pagarConsignados/PagarConsignadosList'
import SimulacaoVenda from './views/vendas/negociacao/simulacaovenda/SimulacaoVenda'

import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primereact/resources/primereact.css'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css'
import Message from './components/utils/Message'
import ConfiguracoesModuloWeb from './forms/configuracao/configuracao/ConfiguracaoesModulo'
import './layout/App.scss'
import './layout/layout.scss'
import './layout/theme.scss'
import { Manutencao } from './views/cadastro/manutencao/Manutencao'
import { ManutencaoList } from './views/cadastro/manutencao/ManutencaoList'
import Marca from './views/cadastro/veiculo/marca/Marca'
import Modelo from './views/cadastro/veiculo/modelo/Modelo'
import Veiculo from './views/cadastro/veiculo/veiculo/Veiculo'
import { DeParaMarcaModelo } from './views/integracao/DeParaMarcaModelo'
import LandingVeiculo from './views/landingVeiculo/LandingVeiculo'
import LandingPix from './views/integracao/financeiro/LandingPix'
import LucroDeVendasReport from './views/report/LucroDeVendasReport'
import { ConsultaEstoque } from './views/vendas/consultaEstoque/ConsultaEstoque'
import { AprovacaoNegociacaoList } from './views/vendas/negociacao/aprovacaoNegociacao/AprovacaoNegociacaoList'
import EntregaVeiculoList from './views/vendas/negociacao/entregaVeiculo/EntregaVeiculoList'
import { OrcamentoBalcao } from './views/vendas/orcamentoBalcao/OrcamentoBalcao'
import { OrcamentoBalcaoList } from './views/vendas/orcamentoBalcao/OrcamentoBalcaoList'
import AnaliseEstoqueVeiculos from './views/vendas/negociacao/analise-estoque/AnaliseEstoqueVeiculos'
import Cor from './views/cadastro/veiculo/cor/Cor'

addLocale('br', {
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sabado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  today: 'Hoje',
  clear: 'Limpar'
})

PrimeReact.ripple = true

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>
          <Switch>
            <PublicRoute exact path="/" component={Login} />
            <PublicRoute exact path="/login" component={Login} />
            <PublicRoute exact path="/login/:dadosIntegracao" component={Login} />
            <PrivateRoute exact ignorePermissaoAcesso path="/home" component={Dashboard} />
            <PrivateRoute exact codigoMenu={6} path="/gerenciamento-estoque-veiculo" component={GerenciamentoEstoqueVeiculos} />
            <PrivateRoute exact codigoMenu={57} path="/analise-estoque" component={AnaliseEstoqueVeiculos} />
            <PrivateRoute exact codigoMenu={1} path="/negociacao" component={Negociacao} />
            <PrivateRoute exact codigoMenu={1} path="/negociacao/:id" component={Negociacao} />
            <PrivateRoute exact codigoMenu={7} path="/negociosfechados" component={NegociosFechadosList} />
            <PrivateRoute exact codigoMenu={12} path="/lucro-de-venda" component={LucroDeVendasReport} />
            <PrivateRoute exact codigoMenu={8} path="/pagarconsignados" component={PagarConsignadosList} />
            <PrivateRoute exact codigoMenu={9} path="/aprovacaonegociacao" component={AprovacaoNegociacaoList} />
            <PrivateRoute exact codigoMenu={16} path="/pessoas-juridicas" component={PessoaJuridicaList} />
            <PrivateRoute exact codigoMenu={16} path="/pessoa-juridica" component={PessoaJuridica} />
            <PrivateRoute exact codigoMenu={16} path="/pessoa-juridica/:id" component={PessoaJuridica} />
            <PrivateRoute exact codigoMenu={17} path="/pessoas-fisicas" component={PessoaFisicaList} />
            <PrivateRoute exact codigoMenu={17} path="/pessoa-fisica" component={PessoaFisica} />
            <PrivateRoute exact codigoMenu={17} path="/pessoa-fisica/:id" component={PessoaFisica} />
            <PrivateRoute exact codigoMenu={18} path="/usuarios" component={UsuarioList} />
            <PrivateRoute exact codigoMenu={18} path="/usuario" component={Usuario} />
            <PrivateRoute exact codigoMenu={18} path="/usuario/:id" component={Usuario} />
            <PrivateRoute exact codigoMenu={19} path="/veiculos" component={VeiculoList} />
            <PrivateRoute exact codigoMenu={19} path="/veiculo" component={Veiculo} />
            <PrivateRoute exact codigoMenu={19} path="/veiculo/:id" component={Veiculo} />
            <PrivateRoute exact codigoMenu={23} path="/cores" component={CorList} />
            <PrivateRoute exact codigoMenu={23} path="/cor" component={Cor} />
            <PrivateRoute exact codigoMenu={23} path="/cor/:id" component={Cor} />
            <PrivateRoute exact codigoMenu={15} path="/manutencoes" component={ManutencaoList} />
            <PrivateRoute exact codigoMenu={15} path="/manutencao" component={Manutencao} />
            <PrivateRoute exact codigoMenu={15} path="/manutencao/:id" component={Manutencao} />
            <PrivateRoute exact codigoMenu={11} path="/orcamentosBalcao" component={OrcamentoBalcaoList} />
            <PrivateRoute exact codigoMenu={11} path="/orcamentoBalcao" component={OrcamentoBalcao} />
            <PrivateRoute exact codigoMenu={50} path="/consultaEstoque" component={ConsultaEstoque} />
            <PrivateRoute exact codigoMenu={11} path="/orcamentoBalcao/:id" component={OrcamentoBalcao} />
            <PrivateRoute exact codigoMenu={52} path="/integracoes" component={IntegracaoList} />
            <PrivateRoute exact codigoMenu={52} path="/integracao" component={Integracao} />
            <PrivateRoute exact codigoMenu={52} path="/integracao/:id" component={Integracao} />
            <PrivateRoute exact codigoMenu={56} path="/depara-marcamodelo" component={DeParaMarcaModelo} />
            <PrivateRoute exact codigoMenu={1} path="/simulacao-venda" component={SimulacaoVenda} />
            <PrivateRoute exact codigoMenu={1} path="/simulacao-venda/:id" component={SimulacaoVenda} />
            <PrivateRoute exact codigoMenu={1} path="/precificar/:id" component={SimulacaoVenda} />
            <PrivateRoute exact codigoMenu={10} path="/avaliacoes" component={AvaliacaoList} />
            <PrivateRoute exact codigoMenu={10} path="/avaliacao" component={Avaliacao} />
            <PrivateRoute exact codigoMenu={10} path="/avaliacao/:id" component={Avaliacao} />
            <PrivateRoute exact codigoMenu={20} path="/marcas" component={MarcaList} />
            <PrivateRoute exact codigoMenu={20} path="/marca" component={Marca} />
            <PrivateRoute exact codigoMenu={20} path="/marca/:id" component={Marca} />
            <PrivateRoute exact codigoMenu={21} path="/modelos" component={ModeloList} />
            <PrivateRoute exact codigoMenu={21} path="/modelo" component={Modelo} />
            <PrivateRoute exact codigoMenu={21} path="/modelo/:id" component={Modelo} />
            <PrivateRoute exact codigoMenu={22} path="/acessorios" component={AcessorioList} />
            <PrivateRoute exact codigoMenu={22} path="/acessorio" component={Acessorio} />
            <PrivateRoute exact codigoMenu={22} path="/acessorio/:id" component={Acessorio} />
            <PrivateRoute exact codigoMenu={53} path="/entrega-veiculo" component={EntregaVeiculoList} />
            <PrivateRoute exact codigoMenu={53} path="/entrega-veiculo/:id" component={EntregaVeiculoList} />
            <PrivateRoute exact codigoMenu={55} path="/configuracao-modulo" component={ConfiguracoesModuloWeb} />

            <Route exact path="/landing-veiculo/:database/:veiculoId/:empresaId" component={LandingVeiculo} />
            <Route exact path="/pagamento-pix-vabank/:database/:codNve/:empresaId" component={LandingPix} />
            <Route path="*" render={props => <ErrorPage throughRoute {...props} />} />
          </Switch>
        </Router>
        <Message />
      </Provider>
    )
  }
}

export default App
